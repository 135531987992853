import {
  Button,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Error({
  hideButton,
  message,
}: {
  hideButton?: boolean;
  message?: string;
}) {
  const bgColor = useColorModeValue("white", "gray.700");
  const navigate = useNavigate();

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const ttl = setTimeout(() => {
      setShowMessage(true);
    }, 2000);
    return () => {
      clearTimeout(ttl);
    };
  }, []);

  return (
    <Flex
      background="rgb(32, 33, 35)" //url(/roomBackground.jpg) no-repeat center center"
      backgroundSize="cover"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        borderRadius={6}
        bg={bgColor}
        gap={3}
        p={8}
        maxW="50vw"
      >
        {!showMessage && <Spinner size="lg" />}
        {showMessage && (
          <>
            <Text fontSize="md">
              {message === "already_in_session" ? (
                <>
                  Vous êtes déjà en communication ou en attente avec un autre
                  professeur. Veuillez tout d'abord quitter la communication en
                  cours avant de rejoindre une autre file d'attente.
                  <br />
                  <br />
                  Si vous venez de quitter une communication, vous pouvez
                  réessayer.
                </>
              ) : (
                <>
                  La connexion s’est interrompue, peux-tu réessayer ?
                  <br />
                  <br />
                  Un problème technique empêche le chargement de la salle de
                  classe virtuelle. Cela peut-être lié à la configuration du
                  réseau utilisé. Si vous tentez une connexion depuis un réseau
                  d'entreprise, la politique de sécurité peut limiter l'accès
                  complet à certaines applications. Veuillez tester depuis un
                  autre réseau ou contacter votre support technique.
                </>
              )}
            </Text>
            {!hideButton && (
              <Button onClick={() => navigate("/")}>Quitter</Button>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
}
