import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  IconButton,
  Input,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Message from "../../components/Chat/Message";
import { useUserDetails } from "../../hooks/provideUserDetails";
import { MdSend } from "react-icons/md";
import {
  getRoomStatus,
  registerToRoom,
  savePreRoomAnswers,
  uploadToRoom,
} from "../../services/auth";
import Error from "./components/Error";
import Loader from "./components/Loader";
import { ChatMessage } from "../../hooks/provideChat";

const WAITING_ACTIONS = [
  "waiting",
  "waiting",
  "question",
  "question",
  "question",
  "download",
  "waiting",
  "yes/no",
  "yes/no",
  "waiting",
  "waiting",
  "waiting",
  "waiting",
  "end",
];

const ANSWER_KEYS = [
  "0",
  "1",
  "2",
  "Classe",
  "Matiere",
  "Question",
  "3",
  "4",
  "Chrome",
  "Ordinateur",
  "5",
  "6",
  "7",
  "8",
  "9",
];

const BOT_MESSAGES = [
  <>
    Bonjour, je suis Waki, l'assistant virtuel de ton prof ! Je vais t'aider à
    préparer ta séance 😊
  </>,
  <>
    Avant de commencer, un petit rappel des règles du profs en ligne !
    <br />
    Sur profs en ligne, tu peux :<br />
    - Poser une question précise sur une partie d'un cours ou un exercice que tu
    n'as pas compris
    <br />
    - Demander au tuteur son avis sur les réponses trouvées à un exercice (sans
    exiger les bonnes réponses)
    <br />
    - Demander des conseils sur un travail à faire
    <br />
    - Demander où trouver des exercices supplémentaires, des vidéos...
    <br />
    - Demander de l’aide pour faire des recherches sur un travail
    <br />
    <br />
    Sur profs en ligne, tu NE peux PAS :<br />
    - Demander la correction d’un devoir entier
    <br />
    - Demander au tuteur de faire l’exercice avec toi
    <br />
    - Demander des réponses si tu n'as déjà pas essayé seul(e)
    <br />- Demander au tuteur de te faire un cours entier
  </>,
  <>
    C'est parti, préparons ta séance !<br />
    <br />
    En quelle classe es-tu ?
  </>,
  <>Pour quelle matière as-tu besoin d'aide ?</>,
  <>Précise en une phrase ta question ou le problème qui te bloque.</>,
  <>
    Pour gagner du temps, scanne ou photographie dès maintenant les documents
    que tu voudrais montrer au prof (sujet du devoir, feuille d'exercices,
    brouillon...).
  </>,
  <>
    Assurons-nous désormais que tu pourras profiter de toutes les
    fonctionnalités de profs en ligne sans problème technique !
  </>,
  <>
    Est-ce que tu utilises le navigateur Google Chrome (ou Mozilla Firefox) ?
  </>,
  <>Est-ce que tu es sur un ordinateur ?</>,
  <>
    Mes conseils pour une meilleure connexion :<br />
    - Privilégie une connexion avec un câble Ethernet plutôt qu’en WiFi
    <br />
    - En WiFi, essaie d’être dans la même pièce que ta box
    <br />
  </>,
  <>
    Si tu es en WiFi, assure-toi que personne chez toi n'est en train de jouer à
    des jeux vidéo en ligne, regarde du streaming ou passe des appels en
    visioconférence. Toutes ces activités consomment une bonne partie de la
    bande passante.
  </>,
  <>
    Si la conversation coupe subitement ou que le tuteur est déconnecté,
    n'hésite pas à rafraichir ta page et à recontacter le tuteur quelques
    minutes après. Le souci vient soit de ta connexion Internet, soit de la
    sienne. Mais pas d'inquiétude, il arrive régulièrement que la connexion
    Internet à certain moment soit insuffisante ou subisse une coupure
    passagère.
  </>,
  <>
    Il ne te reste plus beaucoup de temps à patienter désormais... Sois patient,
    c'est bientôt à ton tour ! 💪
  </>,
  <>
    N'oublie pas de remplir le questionnaire après ta séance. Je lirai ton avis
    pour savoir comment ça s'est passé ! 😊
  </>,
];

const WARNING = (
  <>
    Malheureusement tu n'auras peut-être pas accès à toutes les fonctionnalités
    (audio, tableau blanc, éditeur de texte collaboratif...) car elles ne sont
    pas toutes compatibles avec tous les autres navigateurs. 😕
  </>
);

export default function Entrance() {
  const { id: userId, data } = useUserDetails();
  const bgColor = useColorModeValue("white", "gray.700");
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const roomId = searchParams.get("id");
  const bearer = searchParams.get("bearer");
  const userData = JSON.parse(searchParams.get("data") ?? "{}");

  const answers = useRef<Record<string, string>>({});
  const isListening = useRef(false);
  const currentStep = useRef<number>(1);
  const ref = useRef<HTMLDivElement>(null);
  const fileSelectorRef = useRef<HTMLInputElement>(null);

  const [mainLoading, setMainLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isYesNo, setIsYesNo] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [isAwaitingAnswer, setIsAwaitingAnswer] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      authorId: "bot",
      name: "Waki",
      text: BOT_MESSAGES[0],
      at: new Date().toISOString(),
    },
  ]);

  const handleUpload = async (fileInput: File | null) => {
    if (fileInput === null) {
      return;
    }

    const formData = new FormData();
    formData.append("file", fileInput);

    setUploadInProgress(true);
    const result = await uploadToRoom({
      formData,
      bearer: `${bearer}`,
      studentId: `${userData.id}`,
      id: Number(roomId),
    });
    if (result.error) {
      setMessages((m) => [
        ...m,
        {
          authorId: userId,
          name: "Moi",
          text: "Erreur: votre fichier n'a pas pu être téléchargé. Veuillez réessayer.",
          at: new Date().toISOString(),
        },
      ]);
    } else {
      answers.current[
        "file://" + ANSWER_KEYS[currentStep.current] + Math.random()
      ] = result.data;
      setMessages((m) => [
        ...m,
        {
          authorId: userId,
          name: "Moi",
          text: "Votre fichier a bien été ajouté.",
          at: new Date().toISOString(),
        },
      ]);
    }
    setUploadInProgress(false);
  };

  const handleYesNo = (answer: "yes" | "no") => {
    answers.current[ANSWER_KEYS[currentStep.current]] = message;
    setIsYesNo(false);
    setIsTyping(true);

    setMessages((m) => [
      ...m,
      {
        authorId: userId,
        name: "Moi",
        text: answer === "yes" ? "Oui" : "Non",
        at: new Date().toISOString(),
      },
      ...(answer === "no"
        ? [
            {
              authorId: "bot",
              name: "Waki",
              text: WARNING,
              at: new Date().toISOString(),
            },
          ]
        : []),
    ]);

    setTimeout(() => {
      setMessages((m) => {
        const act = currentStep.current;
        currentStep.current++;
        return [
          ...m,
          {
            authorId: "bot",
            name: "Waki",
            text: BOT_MESSAGES[act],
            at: new Date().toISOString(),
          },
        ];
      });
      handleNextStep();
    }, 2000 + 1000 * Math.random());
  };

  const handleFinishUploading = () => {
    setIsUploading(false);
    setIsTyping(true);
    setTimeout(() => {
      setMessages((m) => {
        const act = currentStep.current;
        currentStep.current++;
        return [
          ...m,
          {
            authorId: "bot",
            name: "Waki",
            text: BOT_MESSAGES[act],
            at: new Date().toISOString(),
          },
        ];
      });
      handleNextStep();
    }, 2000 + 1000 * Math.random());
  };

  const handleSendMessage = () => {
    answers.current[ANSWER_KEYS[currentStep.current]] = message;
    setIsAwaitingAnswer(false);
    setIsTyping(true);

    setMessages((m) => {
      const actMsg = message;
      setMessage("");
      return [
        ...m,
        {
          authorId: userId,
          name: "Moi",
          text: actMsg,
          at: new Date().toISOString(),
        },
      ];
    });

    setTimeout(() => {
      setMessages((m) => {
        const act = currentStep.current;
        currentStep.current++;
        return [
          ...m,
          {
            authorId: "bot",
            name: "Waki",
            text: BOT_MESSAGES[act],
            at: new Date().toISOString(),
          },
        ];
      });
      handleNextStep();
    }, 2000 + 1000 * Math.random());
  };

  const handleNextStep = () => {
    setIsTyping(false);

    const stepType = WAITING_ACTIONS[currentStep.current];
    switch (stepType) {
      case "waiting":
        setIsTyping(true);
        setTimeout(() => {
          setMessages((m) => {
            const act = currentStep.current;
            currentStep.current++;
            return [
              ...m,
              {
                authorId: "bot",
                name: "Waki",
                text: BOT_MESSAGES[act],
                at: new Date().toISOString(),
              },
            ];
          });
          setIsTyping(false);
          handleNextStep();
        }, 2000 + 1000 * Math.random());
        break;

      case "question":
        setIsAwaitingAnswer(true);
        break;

      case "download":
        setIsUploading(true);
        break;

      case "yes/no":
        setIsYesNo(true);
        break;
    }
  };

  const listenToStatus = useCallback(async () => {
    const result = await getRoomStatus({
      bearer: `${bearer}`,
      studentId: `${userData.id}`,
      id: Number(roomId),
    });

    if (result.data === "none") {
      setHasError(true);
      return;
    }

    if (result.data === "in-session") {
      const result = await savePreRoomAnswers({
        bearer: `${bearer}`,
        studentId: `${userData.id}`,
        id: Number(roomId),
        answers: answers.current,
      });
      navigate(
        `/room?teacherId=${Number(roomId)}&data=${JSON.stringify(
          userData
        )}&sessionId=${result.data.id}&token=${result.data.token}&bearer=${
          result.data.bearer
        }`
      );
      return;
    }

    setTimeout(listenToStatus, 2000);
  }, [bearer, navigate, roomId, userData, answers]);

  const reachRoom = useCallback(async () => {
    const fct = async (retrying: boolean = false) => {
      const result = await registerToRoom({
        bearer: `${bearer}`,
        studentId: `${userData.id}`,
        id: Number(roomId),
      });
      if (result.error) {
        if (result.message === "already_in_session" && !retrying) {
          setTimeout(() => fct(true), 6000);
          return;
        }
        setHasError(true);
        setErrorMessage(result.message);
        return;
      }
      setMainLoading(false);
      listenToStatus();
    };
    fct();
  }, [bearer, listenToStatus, roomId, userData.id]);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  });

  useEffect(() => {
    handleNextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isListening.current) {
      return;
    }

    if (roomId === null) {
      setHasError(true);
      return;
    }

    isListening.current = true;
    reachRoom();
  }, [reachRoom, roomId]);

  if (hasError) {
    return <Error message={errorMessage} hideButton />;
  }

  if (mainLoading) {
    return <Loader />;
  }

  return (
    <Flex
      background="rgb(32, 33, 35)"
      backgroundSize="cover"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      padding={3}
    >
      <input
        ref={fileSelectorRef}
        name="file"
        type="file"
        accept="image/*"
        onChange={(e) => handleUpload(e.target.files?.[0] ?? null)}
        style={{ display: "none" }}
      />
      <Flex
        flexDirection="column"
        borderRadius={6}
        bg={bgColor}
        gap={3}
        p={3}
        width="100%"
        height="100%"
        overflow="auto"
      >
        <Text fontSize="xl" fontWeight="semibold">
          Bienvenue !
        </Text>
        <Stack spacing={2} overflow="auto" minHeight={0} flexGrow={1}>
          {messages.map(({ authorId, name, text, at }, id) => (
            <Message
              key={`${authorId}-${id}`}
              name={name}
              text={text}
              at={at}
              mine={
                `${authorId}` === `${userId}` ||
                `${authorId}` === `${data?.id ?? ""}`
              }
            />
          ))}
          {isTyping && <Skeleton borderRadius={6} height="30px" width="80%" />}
          {uploadInProgress && (
            <Skeleton
              marginLeft="20%"
              borderRadius={6}
              height="30px"
              width="80%"
            />
          )}
          <div ref={ref} />
        </Stack>
        {isAwaitingAnswer && (
          <Flex gap={2}>
            <Input
              autoFocus
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              pr="4.5rem"
              placeholder="Message..."
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <IconButton
              onClick={handleSendMessage}
              aria-label={"Envoyer"}
              icon={<MdSend size={20} />}
              isRound
            />
          </Flex>
        )}
        {isUploading && (
          <Flex gap={2}>
            <Button
              colorScheme="gray"
              flexGrow={1}
              onClick={() => fileSelectorRef.current?.click()}
            >
              Ajouter un fichier
            </Button>
            <Button
              onClick={handleFinishUploading}
              colorScheme="green"
              flexGrow={1}
            >
              J'ai terminé
            </Button>
          </Flex>
        )}
        {isYesNo && (
          <Flex gap={2}>
            <Button
              onClick={() => handleYesNo("no")}
              colorScheme="gray"
              flexGrow={1}
            >
              Non
            </Button>
            <Button
              onClick={() => handleYesNo("yes")}
              colorScheme="green"
              flexGrow={1}
            >
              Oui
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
